import { AuthService } from "./auth.service";
import { Component, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, UrlTree, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";
import { LoggedUser } from "@app/models/user";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmUnsavedComponent } from "@app/components/modals/confirm-unsaved/confirm-unsaved.component";
import { QualificationType } from "@app/models/role";

export interface CanComponentDeactivate extends Component {
    hasUnsavedChanges: boolean;
}

@Injectable({
    providedIn: "root",
})
export class RouteGuardService {
    loggedUser: LoggedUser = new LoggedUser();

    constructor(private router: Router, private authService: AuthService, private modalService: NgbModal) {}

    canActivate(
        route: ActivatedRouteSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.authService.isUserLoggedIn) {
            return this.authService.getUser().pipe(
                map((loggedUser: LoggedUser) => {
                    this.loggedUser = loggedUser;
                    const qualifications: number[] = route.data["qualifications"];

                    if (!qualifications || qualifications.length === 0) {
                        return true;
                    } else {
                        const userQualifications = this.loggedUser.utenteEnteRuolo.utentiAbilitazioni.map(
                            (x) => x.abilitazione
                        );

                        const hasAllQualifications = qualifications.every((qualification) =>
                            userQualifications.includes(qualification)
                        );

                        if (hasAllQualifications) {
                            return true;
                        } else {
                            this.router.navigate(["/"]);
                            return false;
                        }
                    }
                }),
                catchError(() => {
                    this.router.navigate(["/"]);
                    return of(false);
                })
            );
        } else {
            this.router.navigate(["accesso"]);
            return false;
        }
    }

    canDeactivate(component: CanComponentDeactivate) {
        if (component.hasUnsavedChanges) {
            const modalRef = this.modalService.open(ConfirmUnsavedComponent);
            return modalRef.closed.pipe(
                switchMap((result: boolean) => {
                    return of(result);
                })
            );
        }
        return true;
    }

    whereRedirect() {
        if (this.authService.isUserLoggedIn) {
            return this.authService.getUser().pipe(
                map((loggedUser: LoggedUser) => {
                    const userQualifications = loggedUser.utenteEnteRuolo.utentiAbilitazioni.map((x) => x.abilitazione);
                    let section = "accesso";

                    if (userQualifications.includes(QualificationType.GET_PROPERTIES)) section = "immobili";
                    else if (userQualifications.includes(QualificationType.GET_FOLDERS)) section = "fascicoli";
                    else if (userQualifications.includes(QualificationType.GET_INSTITUTIONS)) section = "enti";
                    else if (userQualifications.includes(QualificationType.GET_USERS)) section = "utenti";

                    this.router.navigate([section]).then((success) => {
                        success && location.reload();
                    });

                    return false;
                }),
                catchError(() => {
                    this.router.navigate(["accesso"]);
                    return of(false);
                })
            );
        } else {
            this.router.navigate(["accesso"]);
            return false;
        }
    }
}
