<div class="modal-header">
    <div class="modal-title h5">
        <span class="material-icons-outlined fs-2 me-2">exit_to_app</span
        >{{ "it.portal.messages.unsaved.title" | translate }}
    </div>
</div>
<div class="modal-body">
    <p>
        {{ "it.portal.messages.unsaved.message" | translate }}
    </p>
</div>
<div class="modal-footer">
    <button itButton="primary" type="button" (click)="onConfirm()">
        {{ "it.core.confirm" | translate }}
    </button>
    <button itButton="outline-primary" type="button" (click)="onClose()">
        {{ "it.general.abort" | translate }}
    </button>
</div>
