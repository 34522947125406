import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, concatMap, map, Observable, of, switchMap, tap } from "rxjs";
import { Jwt } from "@app/models/jwt";
import { LoggedUser, LoggedUserRole, UserLogin } from "@app/models/user";
import { ResultList } from "../models/resultList";

const TOKEN_KEY = "auth-token";
const REFRESHTOKEN_KEY = "auth-refreshtoken";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    servicesAuthUrl = environment.servicesAuthUrl;
    private isUserLogged = false;
    private userSubject: BehaviorSubject<LoggedUser> = new BehaviorSubject<LoggedUser>(new LoggedUser());
    user$: Observable<LoggedUser> = this.userSubject.asObservable();

    constructor(private http: HttpClient) {}

    get isUserLoggedIn() {
        this.isUserLogged = !!localStorage.getItem(TOKEN_KEY);
        return this.isUserLogged;
    }

    login(data: UserLogin) {
        const httpCaller = data.utenteEnteRuolo
            ? this.http.put<Jwt>(`${this.servicesAuthUrl}/login/ruolo/${data.utenteEnteRuolo}`, {})
            : this.http.post<Jwt>(this.servicesAuthUrl + "/login", data);

        return httpCaller.pipe(
            tap({
                next: (payload: Jwt) => {
                    this.logout();
                    this.saveToken(payload.accessToken);
                    this.saveRefreshToken(payload.refreshToken);
                    this.getUser(true);
                },
            })
        );
    }

    refreshToken() {
        return this.http.post<Jwt>(this.servicesAuthUrl + "/refresh", {}).pipe(
            tap({
                next: (payload: Jwt) => {
                    this.saveToken(payload.accessToken);
                    this.saveRefreshToken(payload.refreshToken);
                },
            })
        );
    }

    logout() {
        localStorage.clear();
        this.userSubject.next(new LoggedUser());
        this.isUserLogged = false;
    }

    getUser(force?: boolean) {
        return this.userSubject.pipe(
            concatMap((user) => {
                if ((!user.utente || force) && this.getToken()) {
                    return this.http.get<LoggedUser>(this.servicesAuthUrl + "/utente", {}).pipe(
                        tap((res) => {
                            this.userSubject.next(res);
                        }),
                        switchMap(() => this.user$)
                    );
                } else return of(user);
            })
        );
    }

    getUserRoles() {
        return this.http.post<ResultList<LoggedUserRole>>(this.servicesAuthUrl + "/login/ruoli", {});
    }

    reloadUser() {
        return this.http.post<Jwt>(this.servicesAuthUrl + "/reload", {}).pipe(
            tap({
                next: (payload: Jwt) => {
                    this.saveToken(payload.accessToken);
                    this.saveRefreshToken(payload.refreshToken);
                },
            })
        );
    }

    hasAllQualifications(requiredQualifications: number[]): Observable<boolean> {
        return this.getUser().pipe(
            map((loggedUser: LoggedUser) => {
                if (!requiredQualifications || requiredQualifications.length === 0) {
                    return true;
                }
                const userQualifications = loggedUser.utenteEnteRuolo.utentiAbilitazioni.map((x) => x.abilitazione);
                return requiredQualifications.every((qualification) => userQualifications.includes(qualification));
            })
        );
    }

    getToken() {
        return this.isUserLoggedIn ? localStorage.getItem(TOKEN_KEY) : "";
    }

    getRefreshToken() {
        return localStorage.getItem(REFRESHTOKEN_KEY);
    }

    saveToken(token: string): void {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.setItem(TOKEN_KEY, token);
    }

    // saveUser(user: LoggedUser): void {
    //     localStorage.removeItem(USER_KEY);
    //     localStorage.setItem(USER_KEY, JSON.stringify(user));
    // }

    saveRefreshToken(token: string): void {
        localStorage.removeItem(REFRESHTOKEN_KEY);
        localStorage.setItem(REFRESHTOKEN_KEY, token);
    }
}
