import { Component, inject, Type } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, CanDeactivateFn } from "@angular/router";
import { CanComponentDeactivate, RouteGuardService } from "@app/services/route-guard.service";

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    return inject(RouteGuardService).canActivate(route);
};

export const canDeactivate: CanDeactivateFn<CanComponentDeactivate> = (component: CanComponentDeactivate) => {
    return inject(RouteGuardService).canDeactivate(component);
};

export const whereRedirect: CanActivateFn = () => {
    return inject(RouteGuardService).whereRedirect();
};
