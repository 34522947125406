import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Qualification, Role } from "@app/models/role";
import { environment } from "src/environments/environment";
import { ResultList } from "@app/models/resultList";

const baseUrl = environment.servicesUsersUrl;

@Injectable({
    providedIn: "root",
})
export class RoleService {
    private roleListSubject = new BehaviorSubject<ResultList<Role>>(new ResultList());
    roleList = this.roleListSubject.asObservable();

    constructor(private http: HttpClient) {}

    setRoleList(roleList: ResultList<Role>): void {
        this.roleListSubject.next(roleList);
    }

    getRoleList(): ResultList<Role> {
        return this.roleListSubject.getValue();
    }

    getAll(all = false): Observable<ResultList<Role>> {
        const params = all ? new HttpParams().set("tutti", all) : undefined;
        return this.http.get<ResultList<Role>>(`${baseUrl}/ruoli`, { params });
    }

    getProfileRoles(): Observable<ResultList<Role>> {
        return this.http.get<ResultList<Role>>(`${baseUrl}/utente/profilo/ruoli`);
    }

    get(id: string | number): Observable<Role> {
        return this.http.get<Role>(`${baseUrl}/ruolo/${id}`);
    }

    create(data: Role): Observable<any> {
        return this.http.post(`${baseUrl}/ruolo`, data);
    }

    update(data: Role): Observable<any> {
        return this.http.put(`${baseUrl}/ruolo/${data.uid}`, data);
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${baseUrl}/ruolo/${id}`);
    }

    getAllQualifications(): Observable<ResultList<Qualification>> {
        return this.http.get<ResultList<Qualification>>(`${baseUrl}/abilitazioni`);
    }
}
