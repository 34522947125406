export class Role {
    uid!: number;
    nome!: string;
    ruoloBase!: boolean;
    enteFiltro!: boolean;
    abilitazioniRuoli!: Qualification[];
    ruoliAssociabili!: AssociateRole[];

    checked?: boolean = false;

    constructor(init?: Partial<Role>) {
        Object.assign(this, init);
    }
}

export enum RoleType {
    SystemAdmi = 1,
    InstitutionAdmin = 2,
    RegionalOperator = 3,
    InstitutionOperator = 4,
    Technician = 5,
}

export class RoleInstitution {
    uid!: number;
    dipendenteEnte!: boolean;
    disabilitato!: boolean;
    ente!: number;
    mail!: string;
    ruolo!: number;
    telefono!: string;
    utentiAbilitazioni!: Qualification[];

    constructor(init?: Partial<RoleInstitution>) {
        Object.assign(this, init);
    }
}

export class AssociateRole {
    uid!: number;
    ruoloFiglio!: number;
}

export class Qualification {
    uid!: number;
    abilitazione!: number;
    nome!: string;
    descrizione!: string;

    checked: boolean = false;
}

export enum QualificationType {
    GET_USERS = 1,
    SEARCH_USERS = 2,
    MANAGE_USERS = 3,
    DELETE_USERS = 4,
    GET_ROLES = 11,
    MANAGE_ROLES = 12,
    GET_INSTITUTIONS = 21,
    MANAGE_INSTITUTIONS = 22,
    GET_FOLDERS = 31,
    MANAGE_FOLDERS = 32,
    WORK_ON_MANAGE_FOLDER = 33,
    MANAGE_PROPERTIES = 34,
    MANAGE_COMPLEXES = 35,
    GET_PROPERTIES = 36,
    GET_COMPLEXES = 37,
    GET_SYSTEM_PARAMETERS = 41,
    MANAGE_SYSTEM_PARAMETERS = 42,
}
