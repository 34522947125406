import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ItModalComponent, ItButtonDirective } from "design-angular-kit";

@Component({
    selector: "app-confirm-unsaved",
    standalone: true,
    imports: [TranslateModule, ItButtonDirective],
    templateUrl: "./confirm-unsaved.component.html",
})
export class ConfirmUnsavedComponent {
    constructor(public activeModal: NgbActiveModal) {}

    onConfirm(): void {
        this.activeModal.close(true);
    }

    onClose(): void {
        this.activeModal.dismiss(false);
    }
}
