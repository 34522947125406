import { Injectable } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class RoutingService {
    private currentRouteSubject = new BehaviorSubject<string>("");
    private pageTypeSubject = new BehaviorSubject<string>("");
    currentRoute$ = this.currentRouteSubject.asObservable();
    pageType$ = this.pageTypeSubject.asObservable();

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            let route = this.activatedRoute;
            while (route.firstChild) {
                route = route.firstChild;
            }
            route.data.subscribe((data) => {
                this.pageTypeSubject.next(data["pageType"]);
            });
            route.url.subscribe((urlSegments) => {
                this.currentRouteSubject.next(urlSegments.map((segment) => segment.path).join("/"));
            });
        });
    }
}
