export enum PageType {
    DETAIL = "detail",
    PROFILE = "profile",
    LOGIN = "login",
}

export class Breadcrumb {
    label?: string;
    link?: string;

    constructor(init?: Partial<Breadcrumb>) {
        Object.assign(this, init);
    }
}
