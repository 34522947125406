import { ResultList } from "@app/models/resultList";

export class PaginateList<T> extends ResultList<T> {
    paginazione!: Pagination;
    ordinamento!: Sorting;

    constructor() {
        super();
        this.risultati = [] as T[];
        this.paginazione = {
            numeroPagina: 0,
            elementiPagina: 0,
            totaleElementi: 0,
            totalePagine: 0,
            numeroElementi: 0,
            ultima: false,
            prima: false,
        };
        this.ordinamento = {
            campo: "",
            verso: "",
        };
    }
}

export interface Pagination {
    numeroPagina: number;
    elementiPagina: number;
    totaleElementi: number;
    totalePagine: number;
    numeroElementi: number;
    ultima: boolean;
    prima: boolean;
}

export interface Sorting {
    campo: string;
    verso: string;
}
