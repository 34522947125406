import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class BreadcrumbService {
    private breadcrumbSubject = new BehaviorSubject<string | null>(null);
    breadcrumbs$ = this.breadcrumbSubject.asObservable();

    setBreadcrumb(breadcrumb: string | null) {
        this.breadcrumbSubject.next(breadcrumb);
    }
}
